<template>
  <div class="main d-flex justify-content-center">
    <div class="privacy-policy">
      <section class="mainarea">
        <h2 class="ttl">特商法に基づく表記</h2>

        <table class="table table-bordered">
          <tr>
            <td>事業者名称</td>
            <td>株式会社イード</td>
          </tr>
          <tr>
            <td>運営責任者</td>
            <td>メディア事業本部本部長土本学</td>
          </tr>
          <tr>
            <td>所在地</td>
            <td>東京都中野区本町1-32-2ハーモニータワー17F</td>
          </tr>
          <tr>
            <td>利用料金および支払時期</td>
            <td>
              講師によりご利用料金は異なります。各講師が設定する相談の詳細ページからご確認ください。<br />
              講師登録者に対して、個別相談契約に基づき本サービス利用者から支払われた利用料金から、<br />
              講師登録者の本サービス利用料を差し引いた金額を個別相談契約に基づく対価として支払います。
            </td>
          </tr>
          <tr>
            <td>利用料金以外の必要料金</td>
            <td>
              特になし <br />
              ※インターネット通信環境等、本サービスのご利用環境に係る費用はお客様のご負担となります。
            </td>
          </tr>
          <tr>
            <td>支払い方法</td>
            <td>クレジットカード</td>
          </tr>
          <tr>
            <td class="text-danger">キャンセル</td>
            <td>
              キャンセル料については、キャンセルの理由に関わらず以下の通りとなります。<br /><br />

              ① 全額返金<br />
              購入から5日未満かつ相談開始24時間前までのキャンセル<br /><br />

              ②相談料から決済手数料3.6％を差引いた金額を返金<br />
              ①を除く相談開始24時間前までのキャンセル<br /><br />

              ③ 返金不可<br />
              購入から5日以上経過かつ相談開始24時間前を過ぎた場合及び相談実施予定日時経過後のキャンセル<br /><br />

              ※ただし、講師の都合により相談がキャンセルになった場合は、全額返金いたします。
            </td>
          </tr>
          <tr>
            <td>役務提供の時期</td>
            <td>講師が設定する相談の日時とします。</td>
          </tr>
          <tr>
            <td>動作環境</td>
            <td>
              本サービスをご利用いただくため次の動作環境を推奨いたします。
              <ul>
                <li>OS</li>
                <li>Windows 7以上</li>
                <li>Mac OS X 10.14以上</li>
                <li>ブラウザー</li>
                <li>Internet Explorer 11 以上</li>
                <li>FireFox 最新版</li>
                <li>Chrome 最新版</li>
                <li>Safari 最新版(Macのみ) 設定</li>
                <li>その他</li>
                <li>JavaScriptが「有効」であること</li>
                <li>Cookieを受け入れる設定であること</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td class="text-danger">お問い合わせ</td>
            <td>
              ReseMom相談online会員様サポートセンター <br />
              <a href="https://soudan.resemom.jp/inquiry" target="_blank">https://soudan.resemom.jp/inquiry</a><br /><br />

              サポート時間：平日 10：00～17：00<br />
              サポートセンター宛に電子メールでご連絡頂けましたら遅滞無く回答致します
            </td>
          </tr>
        </table>
      </section>
    </div>
    <MetaTags />
  </div>
</template>
<script>
export default {
  name: "Sctl",
};
</script>
